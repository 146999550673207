<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'role-links' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin
            </router-link>
            <span class="child">/Assign Role To User </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Assign Role To User</span>
            </v-card-title>
            <v-card-text>
                <div class="add-section">
                    <div>
                        <v-form ref="form">
                            <v-col cols="6" sm="12" lg="6" class="text">
                                <v-col class="pb-0" style="text-align: start;">
                                    <label class="add-text">User Name</label>
                                    <v-combobox v-model="selecteduser" :items="userlist" item-text="username" outlined return-object dense :rules="[rules.required]" clearable required @change="findUsertype()"></v-combobox>
                                </v-col>
                                <v-col class="pb-0" style="text-align: start;">
                                    <label class="add-text">User Type</label>
                                    <v-text-field v-model="usertype" dense outlined readonly :rules="[rules.required]"></v-text-field>
                                </v-col>
                            </v-col>
                            <v-row class="justify-center">
                                <div class="checkbox" v-for="item in rolelist" :key="item.rolename">
                                    <v-checkbox v-model="selectedchkbox" :label="`${item.rolename}`" :value="item.roleid" return-object></v-checkbox>
                                </div>
                            </v-row>

                            <v-col class="text-center">
                                <v-btn depressed color="success" @click="assignRole()">
                                    ADD
                                </v-btn>
                            </v-col>
                        </v-form>
                    </div>
                </div>

                <template>
                    <v-data-table :headers="headers" :items="userrole" :items-per-page="5" class="elevation-1">
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="deletitem(item)">
                                mdi mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard

    export default {
        data: () => ({
            snackbar_msg: "",
            snackbar: false,
            color: "",
            usertype: "",
            selectedchkbox: [],
            rolelist: [],
            userrole: [],
            userlist: [],
            selecteduser: "",
            memberId: "",
            usernamemessage: "",
            rules: {
                required: (value) => !!value || "Required.",
            },
            dialog: false,
            headers: [
                { text: "Role", value: "rolename" },
                { text: "Delete", value: "actions" },
            ],
            editedIndex: -1,
            editedItem: {
                rolename: "",
            },
            defaultItem: {
                rolename: "",
            },
        }),
        mounted() {
            this.onLoad();
        },
        methods: {
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            assignRole: function () {
                if (this.$refs.form.validate()) {
                    const data = {
                        selectedchkbox: this.selectedchkbox,
                        selecteduser: this.selecteduser,
                    };
                    axios
                        .post("/admin/assignRole", data)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.findUsertype();
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            window.console.log(error);
                        });
                }
            },
            findUsertype: function () {
                axios
                    .post("admin/fetchUserType", this.selecteduser)
                    .then((res) => {
                        //console.log("res------->"+res.data.userType)
                        if (res.data.msg == "200") {
                            (this.usertype = res.data.usertype), (this.userrole = res.data.userrole), (this.rolelist = res.data.rolelist), (this.selectedchkbox = []);
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
            },
            onLoad: function () {
                axios
                    .get("admin/fetchAllUser")
                    .then((res) => {
                        //console.log("res------->"+res.data.userType)
                        if (res.data.msg == "200") {
                            (this.userlist = res.data.userlist), (this.rolelist = res.data.rolelist);
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
            },
            deletitem: function (item) {
                console.log("in delete item----------", item);
                const data = {
                    item: item,
                    selecteduser: this.selecteduser,
                };
                axios
                    .post("/admin/deleteUserRole", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.showSnackbar("#4CAF50", "Successfully updated user!!");
                            this.findUsertype();
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        window.console.log(error);
                    });
            },
            save() {
                axios
                    .post("/admin/updateUtype", this.editedItem)
                    .then((res) => {
                        //console.log("res------->"+res.data.userType)
                        if (res.data.msg == "200") {
                            if (res.data.code == 1) this.showSnackbar("#4CAF50", "Successfully updated user!!");
                            else this.showSnackbar("#b71c1c", res.data.message);
                            this.onLoad();
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        window.console.log(error);
                    });
                this.close();
            },
        },
    };
</script>
<style scoped>
    .heading {
        background-color: #3f51b5;
        padding: 0.3rem;
        color: white;
    }
    .v-icon {
        cursor: pointer;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
    .check >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
    .p-btn {
        padding: 0px 12px 12px 12px;
    }
    .add-section {
        display: block;
        text-align: -webkit-center;
    }
    .checkbox {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
</style>
